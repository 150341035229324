.App {
  text-align: center;
  background-color: #1790c9;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
  padding-top: 3vmin;
}

.productImg {
  height: 40vmin;
  padding-top: 10px;
}

.App-footer {
  padding-top: 5px;
  min-height: 9vh;
  display: flex;
  width : 80%;
  margin-left : 10%;
}

.App-footer-child {
  width : 50%;
  float: left;
}

.App-footer-logo {
  width : 80%;
  color : #2f4858;
}

.nunito {
  color : #c69e5a;
  font-family: 'Nunito', sans-serif;
}

.nunito-dark {
  color : #2f4858;
}

.App-footer-right {
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  padding-left: 20px;
  border-left: 2px solid #2f4858;
  margin-bottom: 20px;
  color : #2f4858;
}

.App-header {
  min-height:45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-container {
  background-color: #f4f0ed;
  width : 90%;
  margin-top: 20px;
  height : calc(95vh - 50px);
  border-radius: 50px;
  -webkit-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
-moz-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
position: relative;
}

.title {
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
}

.modal {
  background-color: white;
  border: 1px solid black;
  width: 80%;
  top: 30%;
  left: 5%;
  padding : 20px;
  position: absolute;
}

.subtitle {
  margin-top: 10px;
  text-align: center;
  margin-left: 10%;
  width: 80%;
  color : #2f4858;
  font-size : 20px;
  font-family: 'Cormorant', serif;
  font-weight: 500;
  font-style: italic;
}

.footertitle {
  color: black;
  color : #2f4858;
  font-size: 12px;
  position: absolute;
  bottom : 20px;
  width: 100%;
}

.form-title {
  font-size: 16px;
}

.form-content {
  width : 60%;
  margin-left: 20%;
}

.form-input {
  width : 100%;
  height: 40px;
  border-radius: 35px;
  text-align: center;
  font-size: 25px;
}

.form-btn {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 0px;
  border-radius: 35px;
  font-size : 25px;
  font-weight: bold;
  background-color: #1790c9;
  color : white;
  border : none !important;
}

.form-btn2 {
  width: 80%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 35px;
  font-size : 15px;
  font-weight: bold;
  background-color: #1790c9;
  color : white;
  border : none !important;
}

.form-input::placeholder {
  color : #c6c6c6;
}

.form-footer {
  width : 80%;
  margin-left: 10%;
  font-size: 14px;
  margin-top: 10px;
}

.title3 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}








.keyboardContainer {
  width: 100%;
  height : 20vh;
  z-index: 10005;
  position : absolute;
  bottom: 155px;
  border-radius: 50px;
}

.keyboardContent {
  background-color: white;
  width : 90%;
  margin-left: 5%;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  -moz-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.keyboardRow {
  width: 100%;
  padding: 3px;
  margin-top: 2px;
}

.keyboardBtn {
  width: 30%;
  margin: 5px;
  color : white;
  background-color: #1790c9;
  -webkit-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  -moz-box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  box-shadow: 0px 0px 38px -9px rgba(64, 64, 64, 1);
  border-radius: 10px;
  height: 70px;
  border: none;
  font-size: 38px;
}

.keyboardBtnDefault {
  width: 30%;
  margin: 5px;
  color : #314a59;
  height: 70px;
  border: none;
  font-size: 38px;
  background-color: white;
}
